import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container } from "react-bootstrap"

const SecondPage = () => (
    <Layout pageInfo={{ pageName: "About" }}>
        <SEO title="About" description="Crimson R Games about page. Crimson R Games was founded in 2012 by Aldo Pedro Rangel Montiel. Here you can find our games and apps." />
        <Container className="mt-3 mb-3">
            <Row>
                <h1>About Crimson R Games:</h1>
            </Row>
            <Row>
                Crimson R Games was founded in January 17 2012 by me(Aldo Pedro Rangel Montiel).
                It started just as a simple Blog(powered with Blogger) with some posts about programming, videogames and systems;
                since then I've been adding more content, like tutorials about technologies
                I use or find interesting or to show case some of the projects that I've been involved in
                some way or that are completely mine.
            </Row>
            <Row>
                More recently in 2017, I've started to use Crimson R Games
                as the brand behind my games and apps and using this site as a
                portfolio of some of my work, while also keeping it as my development blog.<br />
                Ever since then I've been focusing more on development of other stuff instead of making
                the site bigger, however, in 2020 it was revamped according to newer technologies.
                As of 2021 the site has become what I had envisioned: the official site for everything I make.
            </Row>
            <br></br>
            <br></br>
            <Row>
                <h1>About the Author:</h1>
            </Row>
            <Row>
                Hi! I'm Aldo Pedro Rangel Montiel, I'm a developer with a burning passion
                for building, imagining, creating and developing Games, Applications and systems.
                
            </Row>
            <Row>
            I decided to become a programmer at the age of 6 mostly inspired by the
                Super Nintendo Game "Super Mario World"; that game fascinated me by all the variety
                of levels it had, the gameplay and the ability to explore a magical "World" through
                a TV screen, thus making me want to be able to create a "Worlds" of my own. Then
                my mother explained me that programmers where the ones that could create such worlds.
                Ever since then I was eager to learn how to program. Years passed and I was able to
                learn programming on my high school years since it was a technical specialization I could
                take(my high school was the CECyT 9 from the IPN, a Mexican educational institution).
            </Row>
            <Row>
            Once I finished high school, I did my very first game: The Chronicles of Magastria: The Training.
                With it I learned the basics of game making, all this self taught by following tutorials and resources
                I could find on the internet at that time. The game was for the Blackberry Playbook and was uploaded to the
                Blackbetty Appworld.
            </Row>
            <Row>
            Some time after I got my first job at the age of 19, due to economical issues I had to
                get a job at that age, fortunately, it was, at first a part time job as a Java developer.
                There I had the opportunity to explore and improve my skills on a wide variety of fields,
                allowing me to have a lot of experience in many different technologies and to grow along
                the industry, specially mobile. The things I've worked with/on thanks to this job(which is full time right now) are:
                Android and iOS apps(either native or with flutter), fullstack development (formerly angularjs, reactjs now and Java on the backend, postgresql database),
                games with Unity3D and Godot Engine, work with computer vision(OpenCV), data analysis(with Python, pandas, matplotlib, scikit-learn) and machine
                learning(tensorflow, keras).
            </Row>
            <Row>
            Alongside with what I've learned and worked on on my jobs, I've also been working on personal
                    and community projects. Crimson R Games and all it's products are part of it. If you're curious
                    about the community projects I've contributed to I suggest you visit my github profile.
            </Row>
            <br></br>
            <div >
                <Row>
                    Thank you for taking the time to read all this. If you'd like to contact me you can find me on:
                </Row>
                <br></br>
                <Row>
                    <Col>
                        <Row className="mt-3">
                            <h6>Stackoverflow:</h6>
                        </Row>

                        <Row className="mb-3">
                            <a href="https://stackoverflow.com/users/4142609/aldoram5">
                                <img src="https://stackoverflow.com/users/flair/4142609.png?theme=clean" width="208" height="58" alt="profile for aldoram5 at Stack Overflow, Q&amp;A for professional and enthusiast programmers" title="profile for aldoram5 at Stack Overflow, Q&amp;A for professional and enthusiast programmers">
                                </img>
                            </a></Row>
                        <Row className="mt-3">
                            <h6>Github:</h6>
                            </Row>
                        <Row className="mb-3">
                            <a href="https://github.com/aldoram5" >aldoram5</a>
                        </Row>
                        <Row  className="mt-3">
                            <h6>Twitter:</h6>
                            </Row>
                        <Row className="mb-3">
                            <a href="https://twitter.com/aldoram5" >aldoram5(don't post too often)</a>
                        </Row>
                    </Col>
                    <Col>
                        <Row  className="mt-3">
                            <h6>Youtube:</h6>
                            </Row>
                        <Row className="mb-3">
                            <a href="https://www.youtube.com/channel/UCh-52p4ek8KRseChgAB-WTA" >aldoram5</a>(Channel for fun).
                        </Row>
                        <Row className="mt-3">
                            <h6>Pixiv:</h6>
                            </Row>
                        <Row className="mt-3">
                            <a href="https://www.pixiv.net/en/users/43804990" >aldoram5</a>(fan art so far.)
                        </Row>
                        <Row className="mt-3">
                            <h6>Email:</h6>
                            </Row>
                        <Row className="mb-3">
                            <a href="mailto:aldo@crimsonrgames.com" >aldo@crimsonrgames.com</a>
                        </Row>
                    </Col>
                </Row>

            </div>
            <br></br>
            <Link to="/">Go back to the homepage</Link>
        </Container>
    </Layout>
)

export default SecondPage
